import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ProductSdk from "../views/ProductSdk.vue";
import Store from '../store'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/productsdk",
    name: "ProductSdk",
    component: ProductSdk,
  },
  {
    path: "/productscdn",
    name: "ProductScdn",
    component: () =>
      import("../views/ProductScdn.vue"),
  },
  {
    path: "/productddos",
    name: "ProductDdos",
    component: () =>
      import("../views/ProductDdos.vue"),
  },
  {
    path: "/productwaf",
    name: "ProductWaf",
    component: () =>
      import("../views/ProductWaf.vue"),
  },
  {
    path: "/productdns",
    name: "ProductDns",
    component: () =>
      import("../views/ProductDns.vue"),
  },
  {
    path: "/productssl",
    name: "ProductSsl",
    component: () =>
      import("../views/ProductSsl.vue"),
  },
  {
    path: "/solutionddos",
    name: "SolutionDdos",
    component: () =>
      import("../views/SolutionDdos.vue"),
  },
  {
    path: "/solutionapi",
    name: "SolutionApi",
    component: () =>
      import("../views/SolutionApi.vue"),
  },
  {
    path: "/solutiondns",
    name: "SolutionDns",
    component: () =>
      import("../views/SolutionDns.vue"),
  },
  {
    path: "/solutionbot",
    name: "SolutionBot",
    component: () =>
      import("../views/SolutionBot.vue"),
  },
  {
    path: "/solutiongame",
    name: "SolutionGame",
    component: () =>
      import("../views/SolutionGame.vue"),
  },
  {
    path: "/solutionstatic",
    name: "SolutionStatic",
    component: () =>
      import("../views/SolutionStatic.vue"),
  },
  {
    path: "/solutiondynamic",
    name: "SolutionDynamic",
    component: () =>
      import("../views/SolutionDynamic.vue"),
  },
  {
    path: "/solutionvideo",
    name: "SolutionVideo",
    component: () =>
      import("../views/SolutionVideo.vue"),
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () =>
      import("../views/AboutUs.vue"),
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () =>
  //     import("../views/Register.vue"),
  // },
  // {
  //   path: "/help",
  //   name: "Help",
  //   component: () =>
  //     import("../views/Help.vue"),
  // },
];



const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error=> error)
}

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(Store)

  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  if(to.name === 'Help'){
    Store.commit('changeShowConcat',false)
  } else {
    Store.commit('changeShowConcat',true)
  }
  next()
})

export default router;
