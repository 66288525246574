<template>
    <ul class="server-list">
              <li class="image0">
                {{$t('about42')}}
                <div class="sm">{{$t('about43')}}</div>
              </li>
              <li class="image1">
                {{$t('about44')}}
                <div class="sm">{{$t('about45')}}</div>
              </li>
              <li class="image2">
                {{$t('about46')}}
                <div class="sm">{{$t('about47')}}</div>
              </li>
              <li class="image3">
                {{$t('about48')}}
                <div class="sm">{{$t('about49')}}</div>
              </li>
              <li class="image4">
                {{$t('about50')}}
                <div class="sm">{{$t('about51')}}</div>
              </li>
              <li v-if="showContact" class="concat-box">
                {{$t("contactUs")}}
                <Concat />
              </li>
            </ul>
</template>
<script>
import Concat from './Concat.vue';
export default {
    name: "ApplyBox",
    props: {
        showContact: {
            type: Boolean,
            defatul: false
        }
    },
    components: { Concat }
}
</script>

<style scoped lang="scss">
.contact-list {
    display: flex;
    .item {
        margin: 0 0.3rem 0;
    }
}

.concat-box {
  margin-top: 1rem;
  .concat {
    position: relative;
    display: flex;
    justify-content: space-between;
    right: 0;
    top: 0;
  }
}
</style>