<template>
   <div class="map-wrapper">
    <div class="map-box">
        <div class="circle-light" v-if="!hide"></div>
        <img src="@/assets/images/map.svg" />
        <div class="liner top-liner" v-if="!hide"></div>
        <div class="liner bottom-liner" v-if="!hide"></div>
        <div class="tit" v-if="!hide">{{$t('about39')}}</div>
        <el-tooltip placement="top" v-for="(item,index) in mapDatas" :key="index" :content="item.name">
          <div class="disc" :class="item.animation === 'an'?['animation', item.id]:item.id"></div>
        </el-tooltip>
      </div>
   </div>
</template>
<script>
export default {
    name: 'Map',
    props: {
        hide: {
            type: Boolean,
            default: false
        }
    },
    watch: {
      '$i18n.locale' () {
        this.init()
      }
    },
    data() {
        return {
            mapDatas: [],
        timer: null
        }
    },
    created() {
    this.init()
    },
    mounted() {
        this.timer = setInterval(() => {
      this.randomDisc()
    }, 5000)
    },
    beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      this.mapDatas = [
      { name: this.$t('js'), id: 'js' },
          { name: this.$t('fj'), id: 'fj' },
          { name: this.$t('zj'), id: 'zj' },
          { name: this.$t('sc'), id: 'sc' },
          { name: this.$t('sx'), id: 'sx' },
          { name: this.$t('wh'), id: 'wh' },
          { name: this.$t('xg'), id: 'xg' },
          { name: this.$t('am'), id: 'am' },
          { name: this.$t('tw'), id: 'tw' },
          { name: this.$t('xjp'), id: 'xjp' },
          { name: this.$t('flb'), id: 'flb' },
          { name: this.$t('mlxy'), id: 'mlxy' },
          { name: this.$t('yn'), id: 'yn' },
          { name: this.$t('adly'), id: 'adly' },
          { name: this.$t('rb'), id: 'rb' },
          { name: this.$t('hg'), id: 'hg' },
          { name: this.$t('els'), id: 'els' },
          { name: this.$t('fg'), id: 'fg' },
          { name: this.$t('dg'), id: 'dg' },
          { name: this.$t('mgjlfny'), id: 'mgjlfny' },
          { name: this.$t('mglsj'), id: 'mglsj' },
          { name: this.$t('jnd'), id: 'jnd' },
          { name: this.$t('mxg'), id: 'mxg' },
          { name: this.$t('ylk'), id: 'ylk' },
          { name: this.$t('alq'), id: 'alq' },
          { name: this.$t('alb'), id: 'alb' },
          { name: this.$t('teq'), id: 'teq' }
        
    ]
    },
    randomDisc() {
      let count = 5
      let mapDatas = JSON.parse(JSON.stringify(this.mapDatas))
      for(let i = 0; i < count; i++) {
        let index = Math.floor(Math.random()*(this.mapDatas.length))
        mapDatas[index].animation = 'an'
      }
      this.mapDatas = mapDatas
      setTimeout(() => {
        this.mapDatas.map(item => {
          item.animation = null
        })
      }, 4000)
      console.log(this.mapDatas)
    }
  }
}
</script>
<style lang="scss" scoped>
.map-box {
  position: relative;
  width: 14.8rem;
  margin: 0 auto;
  font-size: 0.148rem;
  .tit {
    color: #232427;
    font-size: 0.5rem;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 1.5rem;
  }
  img {
    width: 100%;
    position: relative;
  }
  .disc {
    width: 0.08rem;
    height: 0.08rem;
    border-radius: 1rem;
    background-color: #2E527C;
    position: absolute;
    &.animation {
      &:before {
      content: '';
      animation: myfirst 4s;
      width: 0.28rem;
      height: 0.28rem;
      border-radius: 1rem;
      display: block;
      position: absolute;
      left: -0.1rem;
      top: -0.1rem;
    }
    }
    &.js {
      right: 20.12em;
      top: 29.72em;
    }
    &.fj {
      right: 19.85em;
      top: 31.62em;
    }
    &.zj1 {
      right: 2.9rem;
      top: 4.51rem;
    }
    &.zj {
      right: 19.6em;
      top: 30.5em;
    }
    &.sc {
      right: 24.3em;
      top: 30.1em;
    }
    &.sx {
      right: 22.6em;
      top: 29.2em;
    }
    &.wh {
      right: 20.95em;
      top: 30.5em;
    }
    &.xg {
      right: 20.6em;
      top: 32.8em;
    }
    &.am {
      right: 21.3em;
      top: 32.99em;
    }
    &.tw {
      right: 19.2em;
      top: 32.9em;
    }
    &.xjp {
      right: 24.05em;
      top: 38.8em;
    }
    &.flb {
      right: 19.05em;
      top: 35.55em;
    }
    &.mlxy {
      right: 24.41em;
      top: 37.85em;
    }
    &.yn {
      right: 22.63em;
      top: 35.8em;
    }
    &.adly {
      right: 16.2em;
      top: 45.95em;
    }
    &.rb {
      right: 14em;
      top: 28.5em;
    }
    &.hg {
      right: 17.4em;
      top: 28.6em;
    }
    &.els {
      right: 25em;
      top: 16.3em;
    }
    &.dg {
      right: 49.6em;
      top: 22.6em;
    }
    &.fg {
      right: 52em;
      top: 24.6em;
    }

    &.mgjlfny {
      right: 85.6em;
      top: 28em;
    }
    &.mglsj {
      right: 85em;
      top: 29.2em;
    }
    &.jnd {
      right: 83.8em;
      top: 17.96em;
    }
    &.mxg {
      right: 80.9em;
      top: 32em;
    }
    &.ylk {
      right: 40.6em;
      top: 29.5em;
    }
    &.alq {
      right: 37.72em;
      top: 32.4em;
    }
    &.alb {
      right: 40.52em;
      top: 32.4em;
    }
    &.teq {
      right: 43.2em;
      top: 27.7em;
    }
  }
}
.liner {
  height: 2rem;
  position: absolute;
  left: 0;
  width: 100%;
}
.top-liner {
  background-image: linear-gradient(to bottom, #F8F9FB, rgba(248, 249, 251, 0.7), transparent);
  top: 0;
}
.bottom-liner {
  background-image: linear-gradient(to top, #F8F9FB, rgba(248, 249, 251, 0.7), transparent);
  bottom: 0;
}

.circle-light {
  width: 6rem;
  height: 6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3rem;
  margin-top: -3rem;
  background-image: radial-gradient(rgba(255,255,255,1), transparent);
  z-index: 0;
}
@keyframes myfirst
{
  0% {background: transparent;}
  50% {background: rgba(46, 82, 124, 0.2);}
  100% {background: transparent;}
}
</style>