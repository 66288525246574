<template>
    <div class="step-box ac">
          <h2>{{$t('about35')}}</h2>
          <p class="pc">{{$t('about36')}}</p>
          <el-button type="warning" @click="alert($t('contactService'))">{{$t('about37')}}</el-button>
        </div>
</template>
<script>
export default {
    name: 'ApplyBox',
    props: {
    }
}
</script>