<template>
  <div class="home-page">
    <div class="block">
      <div class="container banner1">
        <div class="video-box">
        <video
          class="pc video"
          muted
          loop
          autoplay>
          <source src="@/assets/images/image36.mp4" type="video/mp4">
        </video>
      </div>
        <div class="banner-shadow"></div>
        <div class="context">
          <h2>{{$t('home1')}}</h2>
          <h1>{{$t('home2')}}</h1>
          <ul class="text3">
            <li>{{$t('home3')}}</li>
            <li>{{$t('home4')}}</li>
            <li>{{$t('home5')}}</li>
          </ul>
          <div class="btns">
            <el-button type="primary" @click="alert($t('contactService'))">{{$t('immediateUse')}}</el-button>
            <el-button type="info" @click="handleContact">{{$t("contactUs")}}</el-button>
          </div>
          <div class="supports">
            <div class="tit">{{$t('home6')}}</div>
            <div class="cont">
              <div class="item">Web</div>
              <div class="item">Andriod</div>
              <div class="item">iOS</div>
              <div class="item">Windows</div>
              <div class="item">&More</div>
            </div>
          </div>
        </div>
        <div class="ads">
          <el-row :gutter="20">
            <el-col :md="12" :sm="24">
              <div class="item">
                {{$t('home7')}}
                <div class="sm">{{$t('home8')}}</div>
                <el-button type="warning" @click="handleToHelp">{{$t('immediateUse')}}</el-button>
              </div>
            </el-col>
            <el-col :md="12" :sm="24" class="pc">
              <div class="item">
                {{$t('home9')}}
                <div class="sm">{{$t('home10')}}</div>
                <el-button type="warning" @click="handleContact">{{$t("contactUs")}}</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <div class="block-title">{{$t('home11')}}</div>
        <el-row class="pc">
          <el-col :span="10">
            <steps :step-list="stepList" @handleSwitch="(val)=> {stepList = val}"  />
          </el-col>
          <el-col :span="14">
            <div class="block-content" v-for="(item, index) in stepList" :key="index" v-show="item.slideType">
              <div class="content-title" :class="'title'+index">{{item.label}}</div>
              <div class="content-text">{{item.des}} </div>
              <ul class="content-list">
                <li v-for="(item2,index2) in item.datas" :key="index2">{{item2}}</li>
              </ul>
              <el-button type="primary" @click="() => $router.push({path: item.path})">{{$t('learnmore')}}</el-button>
            </div>
          </el-col>
        </el-row>
        <el-tabs v-model="activeName" class="mobile">
          <el-tab-pane v-for="(item, index) in stepList" :key="index" :label="item.label" :name="item.label" v-show="item.slideType">
            <div class="block-content">
              <div class="content-title" :class="'title'+index">{{item.label}}</div>
              <div class="content-text">{{item.des}} </div>
              <ul class="content-list">
                <li v-for="(item2,index2) in item.datas" :key="index2">{{item2}}</li>
              </ul>
              <el-button type="primary" class="block" @click="() => $router.push({path: item.path})">{{$t('learnmore')}}</el-button>
              <div class="arrow-next" @click="switchNext(stepList, 'activeName', index)"><i class="el-icon-arrow-right" /></div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <hr/>
        <div class="block-title">{{$t('home12')}}</div>
        <el-row class="pc">
          <el-col :span="10">
            <div class="sub-title">{{$t('cloudSafe')}}</div>
            <steps :step-list="stepList2" :hideIndex="[5,6,7]" @handleSwitch="(val)=> {stepList2 = val}" />
            <div class="sub-title">{{$t('cloudQuick')}}</div>
            <steps :step-list="stepList2" :hideIndex="[0,1,2,3,4]" @handleSwitch="(val)=> {stepList2 = val}" />
          </el-col>
          <el-col :span="14">
            <div class="block-content spe" v-for="(item, index) in stepList2" :key="index" v-show="item.slideType">
              <img src="@/assets/images/image1.svg"/>
              <div class="content-title">{{item.label}}</div>
              <div class="content-text">{{item.des}}</div>
              <el-button type="primary" @click="() => $router.push({path: item.path})">{{$t('learnmore')}}</el-button>
            </div>
          </el-col>
        </el-row>
        <el-tabs v-model="activeName2" class="mobile">
          <el-tab-pane v-for="(item, index) in stepList2" :key="index" :label="item.label" :name="item.label" v-show="item.slideType">
            <div class="block-content">
              <img src="@/assets/images/image1.svg"/>
              <div class="content-title" :class="'title'+index" style="background: none; padding-left: 0;">{{item.label}}</div>
              <div class="content-text">{{item.des}} </div>
              <ul class="content-list">
                <li v-for="(item2,index2) in item.datas" :key="index2">{{item2}}</li>
              </ul>
              <el-button type="primary" class="block" @click="() => $router.push({path: item.path})">{{$t('learnmore')}}</el-button>
              <div class="arrow-next" @click="switchNext(stepList2, 'activeName2', index)"><i class="el-icon-arrow-right" /></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <div class="block-title">{{$t('home13')}}</div>
        <div class="flex choose-box2">
          <div class="img pc">
            <img src="@/assets/images/image2.svg" />
          </div>
          <ul class="choose-list">
            <li class="image1">
              {{$t('home14')}}
              <div class="sm">{{$t('home15')}}</div>
            </li>
            <li class="image2">
              {{$t('home16')}}
              <div class="sm">{{$t('home17')}}</div>
            </li>
            <li class="image3">
              {{$t('home18')}}
              <div class="sm">{{$t('home19')}}</div>
            </li>
            <li class="image4">
              {{$t('home20')}}
              <div class="sm">{{$t('home21')}}</div>
            </li>
          </ul>
        </div>
        <div class="step-box flex">
          <div class="item" v-for="(item,index) in stepInfos" :key="index">
            <div class="num">{{index + 1}}</div>
            {{item.label}}
            <div class="sm">{{item.des}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block gray ac">
      <Map />
      <div class="container">
          <div class="data-box">
          <div class="item" v-for="(item,index) in numList" :key="index" :class="'image'+index">
            <span class="num">
              {{item.num}}+
              <span class="unit">{{item.unit}}</span>
            </span>
            <div class="des">{{item.label}}</div>
          </div>
        </div>
        </div>
    </div>
    <!-- <div class="block">
      <div class="container">
        <div class="block-title">{{$t('home22')}}</div>
      <el-row>
        <el-col :md="15" :sm="24">
            <ServerList />
            </el-col>
            <el-col :md="9" :sm="24">
            <HelpBox @handleSuccess="handleSuccess" />
            </el-col>
      </el-row>
      </div>
    </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
import Steps from '@/components/Steps.vue'
import HelpBox from '@/components/HelpBox.vue'
import ServerList from '@/components/ServerList.vue'
import Map from '@/components/Map.vue'
export default {
  name: "Home",
  components: {
    Steps,
    HelpBox,
    ServerList,
    Map
  },
  watch: {
    '$i18n.locale' () {
      this.init()
      this.initNumList()
    }
  },
  data(){
    return {
      numList: [],
      random1: [4890,1790,12976,12368,7831,7642,4571],
      random2: [1892,2971,2277,3368,2834,4631,4472],
      t: null,
      date: null,
      currentDate: null,
      activeName: '',
      activeName2: '',
      stepList: [],
      stepList2: [],
      stepInfos: []
    }
  },
  created() {
     this.init()
  },
  metaInfo () {
    return {
      title: '「BoxCDN」'+this.$t('home81')+'-'+this.$t('home80')
    }
  },
  mounted() {
    this.date = new Date()
    this.currentDate = this.getDate()
    this.initNumList()
    // if(localStorage.getItem('numList') && localStorage.getItem('numList').length>0 && localStorage.getItem('mapDate')) {
    //   if(this.currentDate === localStorage.getItem('mapDate')) {
    //     this.numList = JSON.parse(localStorage.getItem('numList'))
    //   } else {
    //     localStorage.removeItem('numList')
    //     this.initNumList()
    //   }
    // } else {
    //   this.initNumList()
    // }
  },
  beforeDestroy() {
    localStorage.setItem('numList',JSON.stringify(this.numList))
    clearInterval(this.t)
  },
  methods: {
    init() {
      this.activeName = this.$t('productSdk')
      this.activeName2 = this.$t('solutionDdos')
      this.stepList = [
        {
          label: this.$t('productSdk'),
          slideType: 'forward',
          path: '/productsdk',
          des: this.$t('home23'),
          datas: [
          this.$t('home24'),
          this.$t('home25'),
          this.$t('home26'),
          this.$t('home27'),
          this.$t('home28'),
          this.$t('home29')
          ]
        },
        {
          label: this.$t('productScdn'),
          slideType: null,
          path: '/productscdn',
          des: this.$t('home30'),
          datas: [
          this.$t('home31'),
          this.$t('home32'),
          this.$t('home33'),
          this.$t('home26'),
          this.$t('home34'),
          this.$t('home35')
          ]
        },
        {
          label: this.$t('home36'),
          slideType: null,
          path: '/productddos',
          des: this.$t('home37'),
          datas: [
          this.$t('home38'),
          this.$t('home39'),
          this.$t('home40'),
          this.$t('home41'),
          this.$t('home42'),
          this.$t('home43')
          ]
        },
        {
          label: this.$t('productWaf'),
          slideType: null,
          path: '/productwaf',
          des: this.$t('home44'),
          datas: [
          this.$t('home45'),
            this.$t('home31'),
            this.$t('home46'),
            this.$t('home47'),
            this.$t('home48'),
            this.$t('home49')
          ]
        },
        {
          label: this.$t('productDns'),
          slideType: null,
          path: '/productdns',
          des: this.$t('home50'),
          datas: [
          this.$t('home51'),
          this.$t('home52'),
          this.$t('home53'),
          this.$t('home54'),
          this.$t('home55'),
          this.$t('home56')
          ]
        },
        {
          label: this.$t('productSsl'),
          slideType: null,
          path: '/productssl',
          des: this.$t('home57'),
          datas: [
          this.$t('home58'),
          this.$t('home59'),
          this.$t('home60'),
          this.$t('home61'),
          this.$t('home62'),
          this.$t('home63')
          ]
        }
      ]
      this.stepList2 = [
        {
          label: this.$t('solutionDdos'),
          path: '/solutionddos',
          slideType: 'forward',
          des: this.$t('home64'),
        },
        {
          label: this.$t('solutionApi'),
          path: '/solutionapi',
          slideType: null,
          des: this.$t('home65'),
        },
        {
          label: this.$t('solutionDns'),
          path: '/solutiondns',
          slideType: null,
          des: this.$t('home66'),
        },
        {
          label: this.$t('solutionBot'),
          path: '/solutionbot',
          slideType: null,
          des: this.$t('home67'),
        },
        {
          label: this.$t('solutionGame'),
          path: '/solutiongame',
          slideType: null,
          des: this.$t('home68'),
        },
        {
          label: this.$t("staticAcceleration"),
          path: '/solutionstatic',
          slideType: null,
          des: this.$t('home69'),
        },
        {
          label: this.$t("dynamicAcceleration"),
          path: '/solutiondynamic',
          slideType: null,
          des: this.$t('home70'),
        },
        {
          label: this.$t("videoAcceleration"),
          path: '/solutionvideo',
          slideType: null,
          des: this.$t('home71'),
        }
      ],
      this.stepInfos = [
        {
          label: this.$t('home72'),
          des: this.$t('home73')
        },
        {
          label: this.$t('home74'),
          des: this.$t('home75')
        },
        {
          label: this.$t('home76'),
          des: this.$t('home77')
        },
        {
          label: this.$t('home78'),
          des: this.$t('home79')
        },
      ]
    },
    getDate() {
      return this.date.getFullYear()+'-'+(parseInt(this.date.getMonth())+1)+'-'+ this.date.getDate();
    },
    randomArr(arr){
      return arr[parseInt(Math.random() * arr.length, 10)]
    },
    initNumList() {
      let seconds = this.date.getHours()*60*60 + this.date.getMinutes()*60 + this.date.getSeconds()
      this.numList = [
      {
          num: 4*seconds,
          label: this.$t('home82'),
          unit: this.$t('home83')
        },
        {
          num: 2*seconds,
          label: this.$t('home85'),
          unit: this.$t('home83')
        },
        {
          num: this.randomArr(this.random1),
          label: this.$t('home86'),
          unit: 'Gpbs'
        },
        {
          num: this.randomArr(this.random2),
          label: this.$t('home87'),
          unit: this.$t('home84')
        }
      ]
      clearInterval(this.t)
      this.t = setInterval(() => {
        this.numList[0].num += 4
        this.numList[1].num += 2
      }, 1000)
      // localStorage.setItem('numList', JSON.stringify(this.numList))
      // localStorage.setItem('mapDate', this.currentDate)
    },
    handleToHelp() {
      this.$router.push({path: '/help'})
    },
    handleSuccess() {
      this.$message({
        type: 'success',
        message: this.$t('submitSuccsee')
      });
    },
    handleContact() {
      alert(this.$t("contactService"))
    },
    switchNext(list,name,index) {
      if(index === list.length - 1) {
        this[name] = list[0].label
      } else {
        this[name] = list[index+1].label
      }
    }
  }
};
</script>
<style scoped lang="scss">

.home-page {
  .help-box {
    padding: 0; 
    width: auto;
  }
  .banner1 {
    position: relative;
    .banner-shadow {
      width: 100%;
      height: 2.6rem;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(rgba(255,255,255,0.6), #ffffff);
      box-shadow: 0 -50px 50px rgba(255,255,255,0.6);
      z-index: 0;
    }
    h2 {
      font-weight: 500;
      color: #434447;
      margin-bottom: 0.1rem;
    }
  }
  .context {
    width: 5.6rem;
    position: relative;
  }
}
.text3 {
  color: #939497;
  font-size: 0.18rem;
  margin: 0.2rem;
  line-height: 150%;
  li {
    list-style-type: disc;
  }
}
.supports {
  margin-top: 0.7rem;
  .tit {
    font-size: 0.32rem;
    color: #232427;
    font-weight: 700;
    margin-bottom: 0.2rem;
  }
  .cont {
    display: flex;
    justify-content: space-between;
    .item {
      background-repeat: no-repeat;
      background-position: top center;
      background-size: auto 0.48rem;
      color: #BDBDBD;
      padding-top: 0.66rem;
      &:nth-of-type(1) {
        background-image: url(~@/assets/images/web.png);
      }
      &:nth-of-type(2) {
        background-image: url(~@/assets/images/android.png);
      }
      &:nth-of-type(3) {
        background-image: url(~@/assets/images/ios.png);
      }
      &:nth-of-type(4) {
        background-image: url(~@/assets/images/windows.png);
      }
      &:nth-of-type(5) {
        background-image: url(~@/assets/images/more.png);
      }
    }
  }
}
.ads {
  margin-top: 0.8rem;
  position: relative;
  .item {
    background-color: #2E527C;
    border-radius: 0.08rem;
    padding: 0.3rem 0.4rem;
    color: #ffffff;
    font-size: 0.2rem;
    font-weight: 600;
    .sm {
      font-size: 0.16rem;
      margin-bottom: 0.2rem;
      font-weight: 400;
      margin-top: 0.1rem;
    }
  }
}
.data-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .item {
    text-align: left;
    padding-left: 0.74rem;
    background-repeat: no-repeat;
    background-size: 0.7rem;
    background-position: left center;
    width: 25%;
    &.image0 {
      background-image: url(~@/assets/images/icon11.svg)
    }
    &.image1 {
      background-image: url(~@/assets/images/icon12.svg)
    }
    &.image2 {
      background-image: url(~@/assets/images/icon13.svg)
    }
    &.image3 {
      background-image: url(~@/assets/images/icon14.svg)
    }
    .num {
      font-size: 0.36rem;
      color: #232427;
      font-weight: 500;
      margin-bottom: 0.04rem;
      position: relative;
      display: inline-block;
      .unit {
        position: absolute;
        right: -0.25rem;
        top: -0.1rem;
        font-size: 0.14rem;
        font-weight: normal;
      }
    }
    .des {
      color: #939497;
      font-size: 0.14rem;
      padding-left: 0.03rem;
    }
  }
}
@media (max-width: 1179px) {
  .home-page {
    background-image: url('~@/assets/images/image37.png');
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    .banner1 {
      background-image: none;
      h2 {
        margin: 0.7rem 0 0.1rem 0;
      }
    }
  }
  .home-page .context {
    width: 100%;
  }
  .data-box {
    padding-bottom: 0.5rem;
    .item {
      background-position: center top;
      background-size: 0.64rem;
      padding-left: 0;
      text-align: center;
      width: 50%;
      margin-top: 0.35rem;
      background-image: none !important;
      .num {
        font-size: 0.28rem;
        .unit {
          font-size: 0.12rem;
        }
      }
      .des {
        font-size: 0.12rem;
        margin-top: 0.04rem;
      }
    }
  }
  .text3 {
    color: #939497;
    font-size: 0.12rem;
    margin: 0.3rem 0.2rem;
    line-height: 150%;
    li {
      list-style-type: disc;
    }
  }
  .supports {
    margin-top: 0.58rem;
    .tit {
      font-size: 0.2rem;
      color: #232427;
      font-weight: 700;
      margin-bottom: 0.2rem;
    }
    .cont {
      .item {
        background-size: auto 0.36rem;
        padding-top: 0.46rem;
        font-size: 0.12rem;
        font-weight: 200;
      }
    }
  }
  .ads {
    margin-top: 0.6rem;
    .item {
      border-radius: 0.04rem;
      padding: 0.2rem;
      font-size: 0.16rem;
      .sm {
        font-size: 0.14rem;
        margin-top: 0.05rem;
      }
    }
  }
}
</style>
