<template>
    <div class="concat">
          <a class="item skype" target="_back" href="skype:live:.cid.ec19645d57d91f8d?chat"></a>
          <a class="item whatsapp" target="_back" href="https://api.whatsapp.com/send?phone=85294374585"></a>
          <a class="item telegram" target="_back" href="https://t.me/fulling123"></a>
          <a class="item mail" @click="handleCopy"></a>
        </div>
</template>
<script>
export default {
    name: 'ApplyBox',
    props: {
},
methods: {
    handleCopy (data) {
      this.copy('boxcdnsupport@gmail.com')
    },
    copy (data) {
      let elInput = document.createElement('input')
      elInput.value = data
      document.body.appendChild(elInput)
      // 选择对象
      elInput.select()
      console.log(elInput, elInput.value)
      // 执行浏览器复制命令
      this.$message(this.$t('about38'));
      document.execCommand("Copy")
      elInput.remove()
    }
   }
}
</script>


<style lang="scss" scoped>
.concat {
    position: fixed;
    right: 0.34rem;
    top: 30%;
    .item {
        width: 0.56rem;
        height: 0.56rem;
        margin: 0.2rem 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 0.56rem;
        display: block;
        &.skype {
            background-image: url('~@/assets/images/skype.svg');
        }
        &.telegram {
            background-image: url('~@/assets/images/tele.svg');
        }
        &.whatsapp {
            background-image: url('~@/assets/images/what.svg');
        }
        &.mail {
            background-image: url('~@/assets/images/mail.svg');
        }
    }
}
@media (max-width: 1179px) {
    .concat {
        right: 0.15rem;
        top: auto;
        bottom: 0.3rem;
        .item {
        width: 0.36rem;
        height: 0.36rem;
        margin: 0.1rem 0;
        background-size: 0.36rem;
        }
    }
}
</style>