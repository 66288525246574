import request from '../utils/request'

// 注册
export function postRegister (data) {
  return request({
    url: 'api/collections/user/records',
    method: 'post',
    data: data
  })
}

// 获得帮助
export function postCustomer (data) {
  return request({
    url: 'api/collections/customer/records',
    method: 'post',
    data: data
  })
}
