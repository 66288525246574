<template>
    <div class="other-list">
              <h4>{{this.$t('other')}}</h4>
              <ul>
                <li v-if="hideRoute !== 'productsdk'" class="image0"><router-link to="/productsdk" class="router-test">{{this.$t('productSdk')}}</router-link></li>
                <li v-if="hideRoute !== 'productscdn'" class="image1"><router-link to="/productscdn" class="router-test">{{this.$t('productScdn')}}</router-link></li>
                <li v-if="hideRoute !== 'productddos'" class="image2"><router-link to="/productddos" class="router-test">{{this.$t('home36')}}</router-link></li>
                <li v-if="hideRoute !== 'productwaf'" class="image3"><router-link to="/productwaf" class="router-test">{{this.$t('productWaf')}}</router-link></li>
                <li v-if="hideRoute !== 'productdns'" class="image4"><router-link to="/productdns" class="router-test">{{this.$t('productDns')}}</router-link></li>
                <li v-if="hideRoute !== 'productssl'" class="image5"><router-link to="/productssl" class="router-test">{{this.$t('productSsl')}}</router-link></li>
              </ul>
            </div>
</template>
<script>
export default {
    name: 'OtherList',
    props: {
        hideRoute: {
            type: String,
            default: () => ''
        }
    }
}
</script>