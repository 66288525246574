<template>
  <div class="help-box">
    <div class="title" v-if="showTitle">{{$t('getHelp')}}</div>
    <el-form ref="modelForm" :model="modelForm">
      <el-form-item prop="name" :rules="[{required: true, message: $t('pleaseEnter')+$t('name'), trigger: 'change'}]">
        <el-input v-model="modelForm.name" :placeholder="$t('name')+': *'"></el-input>
      </el-form-item>
      <el-form-item prop="contact_type" :rules="[{required: true, message: $t('pleaseSelect')+$t('contactType'), trigger: 'change'}]">
        <el-select v-model="modelForm.contact_type" :placeholder="$t('contactType')+': *'">
          <el-option label="Email" value="email"></el-option>
          <el-option label="Telegram" value="telegram"></el-option>
          <el-option label="Skype" value="skype"></el-option>
          <el-option label="Whatsapp" value="whatsapp"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="contact_info" :rules="[{required: true, message: $t('pleaseEnter')+$t('contactNum'), trigger: 'change'}]">
        <el-input v-model="modelForm.contact_info" :placeholder="$t('contactNum')+': *'"></el-input>
      </el-form-item>
      <el-form-item prop="business_type" :rules="[{required: true, message: $t('pleaseSelect')+$t('businessType'), trigger: 'change'}]">
        <el-select v-model="modelForm.business_type" :placeholder="$t('businessType')+': *'" @change="handleSelect">
          <el-option :label="$t('website')" value="web"></el-option>
          <el-option label="APP" value="app"></el-option>
          <el-option label="IP" value="ip"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="business_info" :rules="[{required: true, message: $t('pleaseEnter')+placeholder, trigger: 'change'}]">
        <el-input v-model="modelForm.business_info" :placeholder="placeholder +': *'"></el-input>
      </el-form-item>
      <el-form-item prop="attack_type">
        <el-input v-model="modelForm.attack_type" :placeholder="$t('attackType')+':'"></el-input>
      </el-form-item>
      <el-form-item prop="region">
        <el-input v-model="modelForm.region" :placeholder="$t('region')+':'"></el-input>
      </el-form-item>
      <el-button type="primary" style="margin-top: 0.3rem" @click="handleHelp">{{$t('getHelp')}}</el-button>
    </el-form>
  </div>
    
</template>
<script>
  import { postCustomer } from '@/api/user'
export default {
    props: {
      showTitle: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      '$i18n.locale' () {
        this.init()
      }
    },
    data() {
        return {
            modelForm: {
              name: '',
              contact_type: '',
              contact_info: '',
              business_type: '',
              business_info: ''
            },
            placeholder: ''
        }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.placeholder = this.$t('pleaseSelect')+this.$t('businessType')
      },
      handleHelp() {
        this.$refs.modelForm.validate((valid) => {
          if (valid){
            postCustomer(this.modelForm).then(res => {
              this.modelForm = {
              name: '',
              contact_type: '',
              contact_info: '',
              business_type: '',
              business_info: '',
            }
              this.$emit('handleSuccess')
            })
          }
        })
      },
      handleSelect(e){
        if(e === 'web') {
          this.placeholder = this.$t('website')+this.$t('address')
        } else if (e === 'ip') {
          this.placeholder = 'IP'+this.$t('address')
        } else if (e === 'app') {
          this.placeholder = this.$t('download')+this.$t('address')
        }
      }
    }
}
</script>