<template>
  <div id="app" :class="$i18n.locale" style="overflow: hidden;">
    <NavBar />
    <router-view />
    <Concat v-if="this.$store.state.showConcat" />
    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Concat from "@/components/Concat.vue";
export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    Concat
  },
  data(){
    return {
    }
  },
  methods: {
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
</style>
