<template>
  <div>
    <div class="block banner bg psdk">
      <div class="container">
        <div class="box">
          <h1>{{$t('productSdk')}}</h1>
          <p>{{$t('product69')}}</p>
          <p class="mobile des">{{$t('product70')}}</p>
          <el-button type="primary">{{$t('consultingBusiness')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block block1 pc">
      <div class="container">
        <el-row>
          <el-col :md="17" :sm="24">
            <h2>{{$t('product29')}}</h2>
            <p>{{$t('product70')}}</p>
            <p>{{$t('product71')}}</p>
          </el-col>
          <el-col :md="7" :sm="24">
            <OtherList hideRoute="productsdk" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('product72')}}</h2>
        <ul class="list1">
          <li class="icon11">
            <h5>{{$t('product73')}}</h5>
            <p>{{$t('product74')}}</p> 
          </li>
          <li class="icon43">
            <h5>{{$t('product75')}}</h5>
            <p>{{$t('product76')}}</p> 
          </li>
          <li class="icon10">
            <h5>{{$t('product77')}}</h5>
            <p>{{$t('product78')}}</p>  
          </li>
          <li class="icon45">
            <h5>{{$t('product79')}}</h5>
            <p>{{$t('product80')}}</p>  
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('product81')}}</h2>
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('add1')" name="first">
            <div class="box1">
              <div class="bg"></div>
              <div class="info">
                <h4>{{$t('product82')}}</h4>
                <p>{{$t('product83')}}</p>
                <p>
                  <b>{{$t('product84')}}</b><br/>
                  {{$t('product85')}}
                </p>
                <p>
                  <b>{{$t('product86')}}</b><br/>
                  {{$t('product87')}}
                </p>
                <p>
                  <b>{{$t('product88')}}</b><br/>
                  {{$t('product89')}}
                </p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('add2')" name="second">
            <div class="box1">
              <div class="bg"></div>
              <div class="info">
                <h4>{{$t('product90')}}</h4>
                <p>{{$t('product91')}}</p>
                <p>
                  <b>{{$t('product92')}}</b><br/>
                  {{$t('product93')}}
                </p>
                <p>
                  <b>{{$t('product86')}}</b><br/>
                  {{$t('product94')}}
                </p>
                <p>
                  <b>{{$t('product95')}}</b><br/>
                  {{$t('product96')}}
                </p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('add3')" name="third">
            <div class="box1">
              <div class="bg"></div>
              <div class="info">
                <h4>{{$t('product97')}}</h4>
                <p>{{$t('product98')}}</p>
                <p>
                  <b>{{$t('product99')}}</b><br/>
                  {{$t('product100')}}
                </p>
                <p>
                  <b>{{$t('product101')}}</b><br/>
                  {{$t('product102')}}
                </p>
                <p>
                  <b>{{$t('product103')}}</b><br/>
                  {{$t('product104')}}
                </p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <ApplyBox />
      </div>
    </div>
  </div>
</template>
<script>
  import OtherList from '@/components/OtherList.vue'
  import ApplyBox from '@/components/ApplyBox.vue'
export default {
  name: "ProductSdk",
  components: {
    OtherList,
    ApplyBox
  },
  mounted() {
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('productSdk')+'-'+ this.$t('home80')
    }
  } 
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
