<template>
  <div class="nav-bar">
    <div class="container">
      <div class="nav-box">
        <div class="nav-l">
          <div class="logo" @click="() => this.$router.push({ path: '/' })"></div>
          <el-menu :default-active="activeIndex" class="el-menu-demo pc" mode="horizontal" @select="handleSelect">
            <el-submenu index="1">
              <template slot="title">{{ $t("productService") }}</template>
              <el-menu-item index="productsdk">
                <router-link to="/productsdk" class="router-test">
                  {{ this.$t('productSdk') }}
                </router-link>
              </el-menu-item>
              <el-menu-item index="productscdn">
                <router-link to="/productscdn" class="router-test">
                  {{ this.$t('productScdn') }}
                </router-link>
              </el-menu-item>
              <el-menu-item index="productddos">
                <router-link to="/productddos" class="router-test">
                  {{ this.$t('productDdos') }}
                </router-link>
              </el-menu-item>
              <el-menu-item index="productwaf">
                <router-link to="/productwaf" class="router-test">
                  {{ this.$t('productWaf') }}
                </router-link>
              </el-menu-item>
              <el-menu-item index="productdns">
                <router-link to="/productdns" class="router-test">
                  {{ this.$t('productDns') }}
                </router-link>
              </el-menu-item>
              <el-menu-item index="productssl">
                <router-link to="/productssl" class="router-test">
                  {{ this.$t('productSsl') }}
                </router-link>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">{{ $t("solution") }}</template>
              <div class="menu-flex">
                <div class="menu-box">
                  <el-menu-item index="2-1" class="sub-title">{{ $t('cloudSafe') }}</el-menu-item>
                  <el-menu-item index="solutionddos">
                    <router-link to="/solutionddos" class="router-test">
                      {{ this.$t('solutionDdos') }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="solutionapi">
                    <router-link to="/solutionapi" class="router-test">
                      {{ this.$t('solutionApi') }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="solutiondns">
                    <router-link to="/solutiondns" class="router-test">
                      {{ this.$t('solutionDns') }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="solutionbot">
                    <router-link to="/solutionbot" class="router-test">
                      {{ this.$t('solutionBot') }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="solutiongame">
                    <router-link to="/solutiongame" class="router-test">
                      {{ this.$t('solutionGame') }}
                    </router-link>
                  </el-menu-item>
                </div>
                <div class="menu-box">
                  <el-menu-item index="2-2" class="sub-title">{{ $t('cloudQuick') }}</el-menu-item>
                  <el-menu-item index="solutionstatic">
                    <router-link to="/solutionstatic" class="router-test">
                      {{ this.$t("staticAcceleration") }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="solutiondynamic">
                    <router-link to="/solutiondynamic" class="router-test">
                      {{ this.$t("dynamicAcceleration") }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="solutionvideo">
                    <router-link to="/solutionvideo" class="router-test">
                      {{ this.$t("videoAcceleration") }}
                    </router-link>
                  </el-menu-item>
                </div>
              </div>
            </el-submenu>
            <el-menu-item index="aboutus" class="navi">
              <router-link to="/aboutus" class="router-test">
                {{ $t("aboutUs") }}
              </router-link>
            </el-menu-item>
            <!-- <el-menu-item index="4" @click="handleContact" class="navi">
                {{$t("contactUs")}}
            </el-menu-item> -->
          </el-menu>
        </div>
        <div class="nav-r">
          <el-dropdown class="lang">
            <span class="el-dropdown-link">
              {{ $t("lang") }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in languages" :key="index" @click.native="handleLan(item)">
                <div class="item-padding">{{ item.label }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button type="primary" @click="handleToRegister" class="pc">{{$t('register')}}</el-button> -->
          <!-- <el-button type="warning" @click="handleToHelp" class="pc">{{$t('beAttack')}}</el-button> -->
          <el-dropdown trigger="click" :hide-on-click="false" class="mobile" ref="mobileDrop">
            <span class="el-dropdown-link nav-btn"></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect">
                  <el-submenu index="1">
                    <template slot="title">{{ $t("productService") }}</template>
                    <el-menu-item index="productsdk">
                      <router-link to="/productsdk" class="router-test">
                        {{ this.$t('productSdk') }}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="productscdn">
                      <router-link to="/productscdn" class="router-test">
                        {{ this.$t('productScdn') }}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="productddos">
                      <router-link to="/productddos" class="router-test">
                        {{ this.$t('productDdos') }}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="productwaf">
                      <router-link to="/productwaf" class="router-test">
                        {{ this.$t('productWaf') }}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="productdns">
                      <router-link to="/productdns" class="router-test">
                        {{ this.$t('productDns') }}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="productssl">
                      <router-link to="/productssl" class="router-test">
                        {{ this.$t('productSsl') }}
                      </router-link>
                    </el-menu-item>
                  </el-submenu>
                  <el-submenu index="2">
                    <template slot="title">{{ $t("solution") }}</template>
                    <div class="menu-flex">
                      <div class="menu-box">
                        <el-menu-item index="2-1" class="sub-title">{{ $t('cloudSafe') }}</el-menu-item>
                        <el-menu-item index="solutionddos">
                          <router-link to="/solutionddos" class="router-test">
                            {{ this.$t('solutionDdos') }}
                          </router-link>
                        </el-menu-item>
                        <el-menu-item index="solutionapi">
                          <router-link to="/solutionapi" class="router-test">
                            {{ this.$t('solutionApi') }}
                          </router-link>
                        </el-menu-item>
                        <el-menu-item index="solutiondns">
                          <router-link to="/solutiondns" class="router-test">
                            {{ this.$t('solutionDns') }}
                          </router-link>
                        </el-menu-item>
                        <el-menu-item index="solutionbot">
                          <router-link to="/solutionbot" class="router-test">
                            {{ this.$t('solutionBot') }}
                          </router-link>
                        </el-menu-item>
                        <el-menu-item index="solutiongame">
                          <router-link to="/solutiongame" class="router-test">
                            {{ this.$t('solutionGame') }}
                          </router-link>
                        </el-menu-item>
                      </div>
                      <div class="menu-box">
                        <el-menu-item index="2-2" class="sub-title">{{ $t('cloudQuick') }}</el-menu-item>
                        <el-menu-item index="solutionstatic">
                          <router-link to="/solutionstatic" class="router-test">
                            {{ this.$t("staticAcceleration") }}
                          </router-link>
                        </el-menu-item>
                        <el-menu-item index="solutiondynamic">
                          <router-link to="/solutiondynamic" class="router-test">
                            {{ this.$t("dynamicAcceleration") }}
                          </router-link>
                        </el-menu-item>
                        <el-menu-item index="solutionvideo">
                          <router-link to="/solutionvideo" class="router-test">
                            {{ this.$t("videoAcceleration") }}
                          </router-link>
                        </el-menu-item>
                      </div>
                    </div>
                  </el-submenu>
                  <el-menu-item index="aboutus" class="item-padding">
                    <router-link to="/aboutus">
                      {{ $t("aboutUs") }}
                    </router-link>
                  </el-menu-item>
                  <el-menu-item index="4" @click="handleContact" class="item-padding">
                    {{ $t("contactUs") }}
                  </el-menu-item>
                </el-menu>
                <el-button type="primary" @click="handleToRegister" class="block"
                  style="margin: 0.2rem 0;">{{ $t('register') }}</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavBar",
  components: {
  },
  watch: {
    '$i18n.locale'() {
      this.init()
    }
  },
  data() {
    return {
      activeIndex: '1',
      languages: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.languages = [
        {
          label: this.$t('en'),
          value: 'en'
        },
        {
          label: this.$t('chs'),
          value: 'chs'
        },
        {
          label: this.$t('ch'),
          value: 'ch'
        },
      ]
    },
    handleSelect(key, keyPath) {
      this.$refs.mobileDrop.hide()
    },
    handleToRegister() {
      this.$router.push({ path: '/register' })
    },
    handleToHelp() {
      this.$router.push({ path: '/help' })
    },
    handleLan(item) {
      this.$i18n.locale = item.value
      localStorage.setItem('lang', item.value)
    },
    handleContact() {
      alert(this.$t("contactService"))
    }
  }
};
</script>
<style lang="scss">
.nav-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 99999;

  .nav-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-l {
      display: flex;
      align-items: center;
    }

    .nav-r {
      display: flex;
      align-items: center;
    }

    .lang {
      background: url(~@/assets/images/lang.svg) no-repeat left center;
      background-size: 0.16rem;
      padding-left: 0.21rem;
      margin-right: 0;

      .el-dropdown-link {
        width: max-content;
        display: inline-block;
      }
    }
  }

  .logo {
    width: 1.57rem;
    height: 0.46rem;
    cursor: pointer;
    background-image: url(~@/assets/images/logo.png);
    background-size: 1.57rem auto;
    margin-right: 0.48rem;
    background-repeat: no-repeat;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  .el-dropdown {
    color: #444444;
    margin-right: 10px;
  }

  .nav-btn {
    background: url(~@/assets/images/drop.svg) no-repeat center center;
    background-size: 0.18rem;
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
  }
}

.menu-flex {
  display: flex;

  .sub-title {
    color: #FF9900 !important;
    cursor: auto;

    &:hover {
      color: #FF9900 !important;
      font-weight: normal !important;
      background-color: transparent !important;
    }
  }
}

.item-padding {
  padding: 0 0.2rem;
}

.el-menu .el-menu-item.navi {
  margin: 0 0.2rem;
  font-weight: 700;

  a {
    vertical-align: top;
  }
}

@media (max-width: 1179px) {
  .nav-box {
    height: 0.56rem;
  }

  .nav-bar {
    .logo {
      margin-left: 0.14rem;
      height: 0.3rem;
      width: 1.5rem;
      background-size: auto 0.3rem;
    }
  }
}</style>

