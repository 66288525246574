import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import {
  showFullScreenLoading,
  tryHideFullScreenLoading
} from './loading'
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '//www.boxcdn.com/', // process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 50000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  if (config.method === 'post' || config.method === 'put') {
    showFullScreenLoading()
  }
  return config
}, error => {
  console.log(error)
  tryHideFullScreenLoading()
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  if (res.config.method === 'post' || res.config.method === 'put') {
    tryHideFullScreenLoading()
  }
  const code = res.data.code || 200
  console.log(88888)

  console.log(code)
  // 获取错误信息
  if (code === 401) {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    )
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    tryHideFullScreenLoading()
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Notification.error({
      title: msg
    })
    tryHideFullScreenLoading()
    return Promise.reject('error')
  } else {
    if (res.data) {
      return res.data
    }
  }
},
error => {
  console.log(error)
  tryHideFullScreenLoading()
  console.log('err' + error)
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  Message({
    message: message,
    type: 'error',
    duration: 5 * 1000
  })
  tryHideFullScreenLoading()
  return Promise.reject(error)
}
)

export default service
