<template>
    <div class="block footer-box">
        <div class="container">
            <el-row class="pc">
                <el-col :span="4"><img style="width: 80%" src="@/assets/images/logo.png" /></el-col>
                <el-col :span="7">
                  <div class="tit">{{$t("contactUs")}}</div>
                  <ul>
                    <li><a target="_back" href="https://t.me/constantine817">Telegram：@constantine817</a></li>
                    <li><a target="_back" href="https://api.whatsapp.com/send?phone=85292441527">Whatapp：+85292441527</a></li>
                  </ul>
                </el-col>
                <el-col :span="5">
                    <div class="tit">{{$t('productService')}}</div>
                  <ul>
                    <li><router-link to="/productsdk">{{this.$t('productSdk')}}</router-link></li>
                    <li><router-link to="/productscdn">{{this.$t('productScdn')}}</router-link></li>
                    <li><router-link to="/productddos">{{this.$t('home36')}}</router-link></li>
                    <li><router-link to="/productwaf">{{this.$t('productWaf')}}</router-link></li>
                    <li><router-link to="/productdns">{{this.$t('productDns')}}</router-link></li>
                    <li><router-link to="/productssl">{{this.$t('productSsl')}}</router-link></li>
                  </ul>
                </el-col>
                <el-col :span="5">
                    <div class="tit">{{$t('solution')}}</div>
                        <ul>
                    <li><router-link to="/solutionddos">{{this.$t('solutionDdos')}}</router-link></li>
                    <li><router-link to="/solutionapi">{{this.$t('solutionApi')}}</router-link></li>
                    <li><router-link to="/solutiondns">{{this.$t('solutionDns')}}</router-link></li>
                    <li><router-link to="/solutionbot">{{this.$t('solutionBot')}}</router-link></li>
                    <li><router-link to="/solutiongame">{{this.$t('solutionGame')}}</router-link></li>
                    <li><router-link to="/solutionstatic">{{this.$t("staticAcceleration")}}</router-link></li>
                    <li><router-link to="/solutiondynamic">{{this.$t("dynamicAcceleration")}}</router-link></li>
                    <li><router-link to="/solutionvideo">{{this.$t("videoAcceleration")}}</router-link></li>
                  </ul>
                </el-col>
                <el-col :span="3">
                    <div class="tit">{{$t('aboutUs')}}</div>
                  <ul>
                    <li><router-link to="/aboutus">{{$t('aboutUs')}}</router-link></li>
                  </ul>
                </el-col>
            </el-row>
            <el-menu :default-active="activeIndex" class="el-menu-vertical-demo mobile">
                <img style="width: 35%; margin-bottom: 0.rem;" src="@/assets/images/logo.png" />
                <el-submenu index="1">
                  <template slot="title">{{$t("contactUs")}}</template>
                  <el-menu-item index="productsdk">
                    <a target="_back" href="https://t.me/fulling123">Telegram：@fulling123</a>
                  </el-menu-item>
                  <el-menu-item index="productscdn">
                    <a target="_back" href="skype:live:.cid.ec19645d57d91f8d?chat">Skype：live:.cid.ec19645d57d91f8d</a>
                  </el-menu-item>
                  <el-menu-item index="productddos">
                    <a target="_back" href="https://api.whatsapp.com/send?phone=85294374585">Whatapp：+85294374585</a>
                  </el-menu-item>
                  <el-menu-item index="productwaf">
                    <a>Email：boxcdnsupport@gmail.com</a>
                  </el-menu-item>
                </el-submenu>
                <el-submenu index="2">
                  <template slot="title">{{$t('productService')}}</template>
                    <div class="menu-box">
                    <el-menu-item index="solutionddos">
                      <router-link to="/productsdk">{{this.$t('productSdk')}}</router-link>
                    </el-menu-item>
                    <el-menu-item index="solutionapi">
                      <router-link to="/productscdn">{{this.$t('productScdn')}}</router-link>
                    </el-menu-item>
                    <el-menu-item index="solutiondns">
                      <router-link to="/productddos">{{this.$t('home36')}}</router-link>
                    </el-menu-item>
                    <el-menu-item index="solutionbot">
                      <router-link to="/productwaf">{{this.$t('productWaf')}}</router-link>
                    </el-menu-item>
                    <el-menu-item index="solutiongame">
                      <router-link to="/productdns">{{this.$t('productDns')}}</router-link>
                    </el-menu-item>
                    <el-menu-item index="solutiongame">
                      <router-link to="/productssl">{{this.$t('productSsl')}}</router-link>
                    </el-menu-item>
                    </div> 
                </el-submenu>
                <el-submenu index="3">
                  <template slot="title">{{$t("solution")}}</template>
                    <div class="menu-flex">
                      <div class="menu-box">
                      <el-menu-item index="2-1" class="sub-title">{{$t('cloudSafe')}}</el-menu-item>
                    <el-menu-item index="solutionddos">
                      <router-link to="/solutionddos" class="router-test">
                        {{this.$t('solutionDdos')}}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="solutionapi">
                      <router-link to="/solutionapi" class="router-test">
                        {{this.$t('solutionApi')}}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="solutiondns">
                      <router-link to="/solutiondns" class="router-test">
                        {{this.$t('solutionDns')}}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="solutionbot">
                      <router-link to="/solutionbot" class="router-test">
                        {{this.$t('solutionBot')}}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="solutiongame">
                      <router-link to="/solutiongame" class="router-test">
                        {{this.$t('solutionGame')}}
                      </router-link>
                    </el-menu-item>
                    </div>
                    <div class="menu-box">
                      <el-menu-item index="2-2" class="sub-title">{{$t('cloudQuick')}}</el-menu-item>
                    <el-menu-item index="solutionstatic">
                      <router-link to="/solutionstatic" class="router-test">
                        {{this.$t("staticAcceleration")}}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="solutiondynamic">
                      <router-link to="/solutiondynamic" class="router-test">
                        {{this.$t("dynamicAcceleration")}}
                      </router-link>
                    </el-menu-item>
                    <el-menu-item index="solutionvideo">
                      <router-link to="/solutionvideo" class="router-test">
                        {{this.$t("videoAcceleration")}}
                      </router-link>
                    </el-menu-item>
                    </div>
                </div>  
                </el-submenu>
                <el-menu-item index="aboutus" class="item-padding">
                  <router-link to="/aboutus">
                    {{$t("aboutUs")}}
                      </router-link>
                    </el-menu-item>
                <el-menu-item index="4" @click="handleContact" class="item-padding">
                    {{$t("contactUs")}}
                </el-menu-item>
              </el-menu>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    data() {
        return{
          activeIndex: '1'
        }
    },
    methods: {
      handleContact() {
        alert(this.$t("contactService"))
      }
    }
}
</script>