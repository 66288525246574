import Vue from "vue";

import router from "./router";
import store from "./store";
// 全局引入框架
import el from 'element-ui';
Vue.use(el)

import App from "./App.vue";



import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ch', // 将要切换的语言，默认取本地缓存的值，若本地没有缓存则默认英语
  messages: {
    'ch': require('@/assets/languages/ch.json'),
    'chs': require('@/assets/languages/chs.json'), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en': require('@/assets/languages/en.json')
  }
});

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount("#app");
