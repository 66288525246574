<template>
    <div class="steps-block">
          <div
            v-for="(item,index) in stepListNew"
            :key="index"
            v-show="!item.hide"
            @click="changeStep(index)"
            class="item"
            :class="item.slideType === 'forward'?'active forward':item.slideType === 'reverse'?'active reverse':''">
            {{item.label}}
          </div>
        </div>
</template>
<script>
export default {
    name: "Steps",
    props: {
        stepList: {
            type: Array,
            default: () => []
        },
        hideIndex: {
          type: Array,
          default: () => []
        }
    },
    data() {
        return {
            currentStepIndex: 0,
            stepListNew: []
        }
    },
    watch: {
      stepList(n,o) {
        this.initList(n)
      }
    },
    created() {
      this.initList(this.stepList)
    },
    methods: {
        initList(n) {
          let stepListNew = JSON.parse(JSON.stringify(n))
          this.stepListNew = stepListNew.map((item,index) => {
            if(this.hideIndex.indexOf(index)>=0) {
              item.hide = true
            } else {
              item.hide = false
            }
            return item
          })
        },
        changeStep(stepIndex) {
          let nList = JSON.parse(JSON.stringify(this.stepListNew))
          nList.map((item,index) => {
              if(index === stepIndex) {
              if(index>this.currentStepIndex) {
                  item.slideType = 'forward'
              } else {
                  item.slideType = 'reverse'
              }
              this.currentStepIndex = stepIndex
              } else {
              item.slideType = null
              }
              return item
          })
          this.$emit('handleSwitch', nList)
        }
    }
}
</script>
<style scoped lang="scss">
.steps-block {
  margin-top: 0.18rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.27rem;
  &:before {
    content: '';
    width: 2px;
    background-color: #cccccc;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .item {
    font-size: 0.18rem;
    position: relative;
    padding: 0.15rem 0;
    padding-left: 0.22rem;
    cursor: pointer;
    line-height: 1;
    &.active {
      color: #2E527C;
      font-weight: 700;
      animation-fill-mode: forwards;
      &:before {
        content: '';
        width: 2px;
        background-color: #3D3D3D;
        position: absolute;
        left: 0px;
        top: 0;
        height: 0.42rem;
      }
      &.forward {
        &:before {
          animation: mymove 0.5s;
          -webkit-animation: mymove 0.5s; /*Safari and Chrome*/
        }
      }
      &.reverse {
        &:before {
          animation: mymoveReverse 0.5s;
          -webkit-animation: mymoveReverse 0.5s; /*Safari and Chrome*/
        }
      }
    }
    :hover {
      color: #2E527C;
      font-weight: 700;
    }

    @keyframes mymove {
      from {top:-0.42rem;}
      to {top:0;}
    }

    @-webkit-keyframes mymove {
      from {top:-0.42rem;}
      to {top:0;}
    }

     @keyframes mymoveReverse {
      from {top:0.42rem;}
      to {top:0;}
    }

    @-webkit-keyframes mymoveReverse {
      from {top:0.42rem;}
      to {top:0;}
    }
  }
}
</style>